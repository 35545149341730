import {Helmet} from "react-helmet";

export const Contact = () => (
    <div className="grow">
        <Helmet>
            <title>Nederlof Transport - Contact</title>
        </Helmet>
        <div className="text-lg bg-gray-50 shadow-xl rounded-xl h-full lg:py-3 px-5 flex lg:flex-row flex-col justify-around">
            <div>
                <h1 className="font-bold text-xl py-5">Contactgegevens:</h1>
                <p>Nederlof Transport</p>
                <p>Van Almondestraat 7</p>
                <p>5666 GC Geldrop</p>
                <p>Tel: <a href="tel:+31614040155">06-14040155</a></p>
                <p>E-mail: <a href="mailto:info@nederlof-transport.nl">info@nederlof-transport.nl</a></p>
                <br/>
                <p>KvK nummer: 68895879</p>
                <p>BTW-nummer: NL001888029B78</p>
            </div>
            <div>
                <img src="/images/contact/nederlof_transport_flags.png" className="w-1/2 mx-auto lg:w-full"
                     alt="Nederlof Transport Logo met vlaggen"/>
            </div>
        </div>
    </div>
);