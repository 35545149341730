export const Carousel = () => (
    <div className="bg-gray-50 shadow-xl px-5 py-5 grow rounded-xl lg:w-10/12 mx-auto">
        <div id="carouselExampleIndicators" className="carousel slide relative lg:w-2/3 mx-auto " data-bs-ride="carousel">
            <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="3"
                    aria-label="Slide 4"
                ></button>
            </div>
            <div className="carousel-inner relative w-full overflow-hidden rounded-xl">
                <div className="carousel-item active float-left w-full">
                    <img
                        src="/images/carousel/nederlof-transport-front-side.jpg"
                        className="block w-full"
                        alt="Nederlof Transport vrachtwagen zijkant"
                    />
                </div>
                <div className="carousel-item float-left w-full">
                    <img
                        src="/images/carousel/blueberry.jpg"
                        className="block w-full"
                        alt="Nederlof Transport vracht bosbessen"
                    />
                </div>
                <div className="carousel-item float-left w-full">
                    <img
                        src="/images/carousel/nederlof-transport-front.jpg"
                        className="block w-full"
                        alt="Nederlof Transport vrachtwagen voorkant"
                    />
                </div>
                <div className="carousel-item float-left w-full">
                    <img
                        src="/images/carousel/berries.jpg"
                        className="block w-full"
                        alt="Nederlof Transport vracht bessen"
                    />
                </div>
            </div>
            <button
                className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
            >
                <span className="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"/>
                <span className="visually-hidden">Previous</span>
            </button>
            <button
                className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
            >
                <span className="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"/>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    </div>
);

