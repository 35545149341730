import {Layout} from "./components/Layout";

import './App.css';
import {NavBar} from "./components/NavBar";
import {Title} from "./components/Title";
import {Carousel} from "./components/Carousel";
import {CustomFooter} from "./components/CustomFooter";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import {About} from "./components/About";
import {Contact} from "./components/Contact";

function App() {
    return (
        <Layout>
            <NavBar/>
            <Title/>
            <div className="mb-auto">
                <Router>
                    <Routes>
                        <Route exact path="/" element={<Carousel/>}/>
                        <Route path="/over-ons/" element={<About/>}/>
                        <Route path="/contact/" element={<Contact/>}/>
                        <Route element={<Carousel/>}/>
                    </Routes>
                </Router>
            </div>
            <CustomFooter/>
        </Layout>

);
}

export default App;
