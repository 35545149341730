import {Helmet} from "react-helmet";

export const About = () =>(
    <div className="grow">
        <Helmet>
            <title>Nederlof Transport - Over Ons</title>
        </Helmet>
        <div className="text-xl bg-gray-50 shadow-xl rounded-xl h-full lg:py-3 px-5 flex flex-col lg:flex-row justify-around">
            <div>
                <h1 className="font-bold text-xl py-3 lg:py-5">Over ons</h1>
                <p className="text-lg"> Met meer dan 20 jaar ervaring in nationaal en internationaal transport is Nederlof Transport uw betrouwbare
                    logistieke partner voor alle mogelijke vormen van wegtransport. Nederlof Transport heeft de beschikking over een
                    eigen koeltrailer.
                </p>
            </div>
            <div >
                <img src="/images/about/nederlof-transport-side.jpg"
                     alt="Nederlof Transport Zijkant Vrachtwagen"
                     className="lg:float-right lg:w-3/4 rounded-lg w-1/2 mx-auto" />
            </div>
        </div>
    </div>
);