import React from "react";
import {Helmet} from "react-helmet";

export const Layout = (props) => (

    <div className="">
        <Helmet>
            <meta charSet="utf-8" />
            <title>Nederlof Transport</title>
            <link rel="canonical" href="https://nederlof-transport.nl" />
            <meta content="Uw betrouwbare logistieke partner voor nationaal en internationaal wegtransport. Voor meer informatie neem contact op via info@nederlof-transport.nl of 0614040155"
                  name="description"/>
            <meta content="Nederlof Transport" name="application-name" />
        </Helmet>
        <div className="lg:w-3/4 mx-auto flex flex-col h-screen justify-between ">
            {props.children}
        </div>
    </div>
)